<template lang="pug">
.ai-video
  .row
    .col-12.d-flex.align-items-center.justify-content-center
      transition-group(name="fade")
        iframe(
          v-if="showVideo"
          key="aiVideo"
          ref="iframe"
          src="https://www.youtube.com/embed/nk2E5VqZ0tg?autoplay=1"
          title="Introducing OptiMonk AI"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
        )
        img.cursor-pointer(
          key="aiVideoCover"
          v-else
          @click="playVideo"
          :src="require('@/assets/admin/img/ai-features/video_thumbnail.jpg')"
        )
</template>

<script>
  import { track } from '@/services/xray';

  export default {
    data: () => ({
      showVideo: false,
    }),
    methods: {
      playVideo() {
        track('ai_page_play_video');
        this.showVideo = true;
      },
    },
  };
</script>

<style lang="sass">
  .ai-video
    position: relative
    margin-top: 5.5rem
    padding: 4.5rem 0
    height: 42.25rem
    overflow: hidden
    img
      max-width: 56.25rem
      border-radius: 1rem

    iframe
      max-width: 80vw
      width: 56.25rem
      aspect-ratio: 16 / 9
      border-radius: 1rem
</style>
