import { mapState, mapMutations } from 'vuex';
import SAVE_AI_FEATURE_REQUEST from '@/graphql/SaveAiFeatureRequest.gql';

const VALID_TYPES = ['ecommerce', 'saas', 'agency'];

export default {
  data: () => ({
    isAiEarlyAccessLoading: false,
  }),
  computed: {
    ...mapState(['account']),
    type() {
      const business = (this.account?.settings?.qualifications?.business ?? '').toLowerCase();

      return VALID_TYPES.includes(business) ? business : 'ecommerce';
    },
    alreadyRequestedAI() {
      const profile = this.account?.profile ?? {};
      return Object.keys(profile).some((key) => /^aiRequest_/.test(key));
    },
  },
  methods: {
    ...mapMutations(['setProfileKey']),
    async saveAiEarlyAccessRequest(feature) {
      this.isAiEarlyAccessLoading = true;
      await this.$apollo.mutate({
        mutation: SAVE_AI_FEATURE_REQUEST,
        variables: { feature },
      });
      this.isAiEarlyAccessLoading = false;
    },
    preSetProfileKey(key) {
      this.setProfileKey({ key, value: new Date() });
    },
  },
};
