<template lang="pug">
.ai-block
  .row(:class="{ odd, even }")
    .col-5.d-flex.flex-column.justify-content-center
      om-heading.ai-block-preheading.text-uppercase.om-ai-gradient.ml-0(h5) {{ $t(`ai.blocks.${name}.preHeading`) }}
      om-heading.ai-block-title(h3) {{ $t(`ai.blocks.${name}.title`) }}
      om-body-text.ai-block-description(bt400md) {{ $t(`ai.blocks.${name}.description`) }}
      om-link.ai-block-learn-more(primary @click="openBlank(name)") {{ $t('learnMore') }}
    .col-7
      video.ai-block-video(
        v-if="animated"
        :src="require(`@/assets/admin/video/ai-features/${$t(`ai.blocks.${name}.video`)}`)"
        autoplay
        loop
        muted="muted"
        playsinline
        controlslist="nodownload"
      )
      img.ai-block-img(
        v-else
        :src="require(`@/assets/admin/img/ai-features/${$t(`ai.blocks.${name}.image`)}`)"
      )
</template>

<script>
  import { track } from '@/services/xray';

  const BLOCK_TO_EVENT = {
    productPageOptimizer: 'pageOptimizer',
    smartABTesting: 'abTest',
    smartABTesting_b: 'abTest',
    smartPopups: 'smartPopup',
    smartPopups_b: 'smartPopup',
    smartPersonalizer: 'smartPersonalizer',
    smartPersonalizer_b: 'smartPersonalizer',
  };

  export default {
    props: {
      name: { type: String, required: true },
      odd: { type: Boolean, default: false },
      even: { type: Boolean, default: false },
      animated: { type: Boolean, default: false },
    },
    methods: {
      openBlank(block) {
        const key = BLOCK_TO_EVENT[block] ?? block;
        track(`ai_page_learnMore_${key}`);
        const link = this.$t(`ai.blocks.${block}.learnMoreLink`);
        window.open(link, '_blank');
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .ai-block
    .row.even
      flex-direction: row-reverse
    &-preheading
      margin-bottom: 1.5rem
    &-title
      margin-bottom: .5rem
    &-description
      margin-bottom: 1.5rem
      color: $om-gray-700
    &-cta
      width: max-content

    &-video
      max-width: 100%
</style>
