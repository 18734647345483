<template lang="pug">
.ai-features.container-fluid
  Intro(@back="onBack")
    RequestAccess(
      :loading="isAiEarlyAccessLoading"
      @request="onRequest('ai_page_early_access')"
      @later="onLater"
    )
    Rates
  Video
  .ai-dynamic-content.row
    img.before-shape.shape-1(
      :src="require('@/assets/admin/img/ai-features/video_after_shape.png')"
    )
    img.before-shape.shape-2(
      :src="require('@/assets/admin/img/ai-features/video_after_shape2.png')"
    )
    .col-12.ai-dynamic-content-wrapper
      .ai-wrapper-900
        om-heading.ai-dynamic-content-title.text-center(h1) {{ $t(`ai.blockTitles.${type}`) }}
      .ai-wrapper-1100.d-flex.flex-column.gap-80
        DynamicBlock(
          v-for="(blockName, index) in blockNames"
          :key="blockName"
          :animated="animatedBanners[blockName]"
          :name="blockName"
          :odd="!!(index % 2)"
          :even="!(index % 2)"
        )
    RequestAccess.col-12(
      v-if="!alreadyRequestedAI"
      :title="$t('ai.blocksEnd.title')"
      :loading="isAiEarlyAccessLoading"
      @request="onRequest('ai_page_early_access')"
      @later="onLater"
    )
  .row
    .col-12
      .ai-wrapper-900
        BookNow(@book="onRequest('ai_page_book')" :loading="isAiEarlyAccessLoading")
</template>

<script>
  import aiFeaturesMixin from '@/mixins/aiFeatures';
  import Intro from '@/components/AIFeatures/Intro.vue';
  import Rates from '@/components/AIFeatures/Rates.vue';
  import Video from '@/components/AIFeatures/Video.vue';
  import DynamicBlock from '@/components/AIFeatures/DynamicBlock.vue';
  import RequestAccess from '@/components/AIFeatures/RequestAccess.vue';
  import BookNow from '@/components/AIFeatures/BookNow.vue';
  import { track } from '@/services/xray';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    components: { Intro, Rates, Video, BookNow, DynamicBlock, RequestAccess },
    mixins: [aiFeaturesMixin],
    computed: {
      ...mapGetters(['onboardingFinished']),
      blockNames() {
        if (this.type === 'agency') {
          return ['smartABTesting', 'smartPersonalizer_b', 'productPageOptimizer', 'smartPopups'];
        }

        if (this.type === 'saas') return ['smartABTesting_b', 'smartPopups_b', 'smartPersonalizer'];

        return ['smartABTesting', 'productPageOptimizer', 'smartPopups'];
      },
      animatedBanners() {
        return {
          smartABTesting: true,
          smartPopups: true,
          productPageOptimizer: true,
        };
      },
    },
    methods: {
      ...mapActions(['finishOnboarding']),
      async onRequest(type = 'ai_page_early_access') {
        track(type);
        await this.saveAiEarlyAccessRequest(type);
        if (this.onboardingFinished) {
          this.$router.push({ name: 'ai-success' });
        } else {
          await this.finishOnboarding();
          this.$router.push({ name: 'onboarding-ai-success' });
        }
      },
      async onLater() {
        if (!this.onboardingFinished) {
          await this.finishOnboarding();
        }
        track('ai_page_later');
        this.$router.push({ name: 'dashboard' });
      },
      onBack() {
        track('ai_page_back');
        // when the page was opened directly there is no valid history
        // so instead of go back to about:blank go to dashboard...
        if (window.history.length > 2) {
          return this.$router.go(-1);
        }
        this.$router.push({ name: 'dashboard' });
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .ai-dynamic-content
    position: relative
    background: #F9EEF5
    padding: 5rem 0
    .gap-80
      gap: 5rem
    &-wrapper
      display: flex
      flex-direction: column
      gap: 5rem
  .before-shape
    position: absolute
    width: 16.875rem
    &.shape-1
      left: -4.5rem
      top: -6rem
    &.shape-2
      right: 0
      top: 3rem
  .ai-wrapper-900
    max-width: 56.25rem
    margin: 0 auto
  .ai-wrapper-1100
    max-width: 68.75rem
    margin: 0 auto
  .ai-fancy-text
    .om-ai-gradient
      margin-left: .25rem
  .om-ai-gradient
    display: inline-block
    background: -webkit-linear-gradient(0deg, #8444E1 0%, #ED5A29 100%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
</style>
