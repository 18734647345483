<template lang="pug">
.ai-book-now.d-flex.flex-column
  om-heading.ai-book-now-block-title.text-center(h1 v-html="$t('ai.book.title')")
  .d-flex.ai-book-now-content
    .col-6
      img.ai-book-now-content-img(
        v-if="$i18n.locale === 'hu'"
        :src="require('@/assets/admin/img/ai-features/ai-book-csilla.png')"
      )
      img.ai-book-now-content-img(
        v-else
        :src="require('@/assets/admin/img/ai-features/ai-book-kata.jpg')"
      )
    .col-6
      .ai-book-now-content-pre-heading.font-weight-bold.om-ai-gradient.mb-2(bold) {{ $t('ai.book.details.preHeading') }}
      om-heading.ai-book-now-content-title(h3) {{ $t('ai.book.details.title') }}
      om-body-text.ai-book-now-content-description(bt400md) {{ $t('ai.book.details.description') }}
      om-button.ai-book-now-content-cta(primary large :loading="loading" @click="$emit('book')") {{ $t('ai.book.details.cta') }}
</template>

<script>
  export default {
    props: { loading: { type: Boolean, default: false } },
  };
</script>

<style lang="sass">
  .ai-book-now
    padding: 5rem 0
    justify-content: space-evenly
    gap: 5rem
    &-content
      max-height: 20.3125rem // 325px
      &-img
        height: 100%
      &-pre-heading
        text-transform: uppercase
      &-title
        margin-bottom: 1.5rem
      &-description
        margin-bottom: 2.5rem
</style>
