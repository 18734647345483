import { render, staticRenderFns } from "./Rates.vue?vue&type=template&id=689786a6&lang=pug&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Rates.vue?vue&type=custom&index=0&blockType=styles&lang=sass"
if (typeof block0 === 'function') block0(component)

export default component.exports