<template lang="pug">
.ai-intro-block-wrapper
  .ai-intro-top-left
    .row.flex-column
      .col-auto
        inline-svg(:src="require('@/assets/admin/svg/optimonk-logo-basic.svg')")
      .col-auto.mt-5
        om-link(primary @click="$emit('back')" withIconLeft) {{ $t('back') }}
  .ai-intro.row
    .col-12.d-flex.align-items-center.flex-column.justify-content-center
      .ai-intro-wrapper.d-flex.align-items-center.flex-column.justify-content-center
        om-heading.om-ai-gradient.ai-intro-preheading.text-center.text-uppercase(
          h5
          v-html="$t('ai.preHeadTitle')"
        )
        om-heading.ai-intro-page-title.text-center.mt-4(h1 v-html="$t(`ai.pageTitle.${type}`)")
        slot
</template>

<script>
  import aiFeaturesMixin from '@/mixins/aiFeatures';

  export default {
    mixins: [aiFeaturesMixin],
  };
</script>

<style lang="sass" scoped>
  .ai-intro
    min-height: 27rem
    background: url('~@/assets/admin/img/ai-features/intro_bg.jpg')
    background-size: cover
    background-position: center center

    &-page-title
      max-width: 800px

    &-block-wrapper
      position: relative
      .ai-intro-top-left
        position: absolute
        z-index: 3
        top: 1.25rem
        left: 1.25rem
    &-wrapper
      margin-top: -4rem
</style>
