<template lang="pug">
.ai-request-access(:class="{ 'has-title': !!title }")
  om-heading.ai-request-access-title.text-center(v-if="title" h1) {{ title }}
  .row.align-items-center.justify-content-center(v-if="alreadyRequestedAI")
    .col-auto
      .ai-request-access-confirmed.text-center.d-flex.align-items-center.justify-content-center
        UilCheckCircle.mr-1(size="1.5em")
        | {{ $t('ai.requestConfirmed') }}
  .row.align-items-center.justify-content-center(v-else)
    .col-auto
      om-button.ai-block-cta(primary large :loading="loading" @click="$emit('request')") {{ $t('ai.requestAccess') }}
    .col-auto
      om-link.ai-block-cta(secondary large @click="$emit('later')") {{ $t('maybeLater') }}
</template>

<script>
  import aiFeaturesMixin from '@/mixins/aiFeatures';
  import { UilCheckCircle } from '@iconscout/vue-unicons';

  export default {
    components: { UilCheckCircle },
    mixins: [aiFeaturesMixin],
    props: {
      title: { type: String, default: null },
      loading: { type: Boolean, default: false },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .ai-request-access
    width: max-content
    &:not(.has-title)
      margin-top: 2rem
    &-confirmed
      padding: .75rem 5rem
      font-size: 1rem
      line-height: 1.75
      background: $om-alert-green-50
      color: $om-alert-green-600
    &-title
      margin-top: 5rem
      margin-bottom: 2rem
    .row
      gap: 20px
</style>
